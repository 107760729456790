import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import {
  useCreate,
  useGetIdentity,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useParams } from "react-router-dom";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputSelectField from "../common/InputComponents/InputSelectField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputField2 from "../common/InputComponents/InputField2";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import { DevTool } from "@hookform/devtools";
import { JSONTree } from "react-json-tree";
import GoogleAutoComplete from "../common/InputComponents/GoogleAutocomplete";
import InputCurrencyField from "../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";
import NoDocReasonRadioButtons from "../common/InputComponents/NoDocReasonRadioButtons";
import { QueryClient } from "react-query";
import { dataProvidersAts } from "../../dataproviders/DataProvidersAts";
import LoadingSpinner from "../common/loadingSpinner";
import moment from "moment";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { method } from "lodash";
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";

export const AgentList = (props) => {
  const { Office, register, fieldValue, newuserurl } = props;
 
  // filter a data
  let filterData: any = [];
  if (fieldValue !== null) {
    let filter = Office?.filter(
      (item) => item.OfficeName == fieldValue.label
    )[0];
    filterData = filter;
  }
  const { data: identity }: any = useGetIdentity();
  const { permissions } = usePermissions();
  // const {subscriberid:id}= identity;
  const userRole =
    useSelector((state: any) => state?.users?.role) || identity?.userrole;
 
  // call get agent list data
  const adminurl = filterData?.URL;
  const agenturl = newuserurl;
  const { data, isLoading, error } = useGetList(
    "getagentlist",
    {

      filter: {
        url:
          userRole === "Agent" ? agenturl : adminurl, SubscriberID: filterData?.SubscriberID
      },
    },
    { enabled: adminurl || agenturl && filterData?.SubscriberID ? true : false }
  );

  return (
    <>
      {/* <JSONTree data={data?.length }/> */}
      {isLoading ? (
        <div className="col-sm-4 mr-b30 mt-3 static-d-info d-flex align-items-center">
          <CircularProgress size="30px" />
          <h5>&nbsp; &nbsp; Loading...</h5>
        </div>
      ) : data && data?.length > 0 ? (
        <div className="col-sm-4 mr-b30">
          <InputSelectField
            label="Agent"
            source={`agent`}
            options={data?.map((item) => ({
              label: item.FullName,
              value: item.ASPNetUserID,
              id: item.id,
            }))}
            labelAsteriskClass={"color-red"}
            labelClass={"lable-title"}
            requiredClass={{
              fontSize: "70%",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const FloorPlan = (props) => {
  const { FloorPlan, watch, setValue } = props;

  // filter a data
  let filterData: any = [];
  if (watch().property !== null) {
    let filter = FloorPlan?.filter(
      (item) => item.PropertyName == watch().property.label
    )[0];
    filterData = filter;
  }

  // call get Floor list data
  const { data, isLoading, error } = useGetList(
    "getfloorlist",
    {
      filter: {
        PropertyID: filterData?.ID,
        SubscriberID: filterData?.SubscriberID,
      },
    },
    { enabled: filterData?.ID && filterData?.SubscriberID ? true : false }
  );

  return (
    <>
      {isLoading ? (
        <div className="col-sm-4 mr-b30 mt-3 static-d-info d-flex align-items-center">
          <CircularProgress size="30px" />
          <h5>&nbsp; &nbsp; Loading...</h5>
        </div>
      ) : data && data?.length > 0 ? (
        <div className="col-sm-4 mr-b30">
          <InputSelectField
            label="Select Floor Plan"
            source={`floor`}
            options={data?.map((item) => ({
              label: item.FloorCode,
              value: item.id,
            }))}
            labelAsteriskClass={"color-red"}
            labelClass={"lable-title"}
            requiredClass={{
              fontSize: "70%",
            }}
            required={false}
            onChangeCallback={() => setValue("unit", null)}
          />
        </div>
      ) : (
        <></>
      )}
      <UnitPlan UnitPlan={data} fieldValue={watch().floor} />
    </>
  );
};

export const UnitPlan = (props) => {
  const { UnitPlan, fieldValue } = props;

  // filter a data
  let filterData: any = [];
  if (fieldValue !== null) {
    let filter = UnitPlan?.filter(
      (item) => item.FloorCode == fieldValue.label
    )[0];
    filterData = filter;
  }

  // call get unit list data
  const { data, isLoading, error } = useGetList(
    "getunitlist",
    {
      filter: {
        PropertyID: filterData?.ID,
        SubscriberID: filterData?.SubscriberID,
      },
    },
    { enabled: filterData?.ID && filterData?.SubscriberID ? true : false }
  );

  return (
    <>
      {isLoading ? (
        <div className="col-sm-4 mr-b30 mt-3 static-d-info d-flex align-items-center">
          <CircularProgress size="30px" />
          <h5>&nbsp; &nbsp; Loading...</h5>
        </div>
      ) : data && data?.length > 0 ? (
        <div className="col-sm-4 mr-b30">
          <InputSelectField
            label="Unit"
            source={`unit`}
            options={data?.map((item) => ({
              label: item.UnitName,
              value: item.ID,
            }))}
            labelAsteriskClass={"color-red"}
            labelClass={"lable-title"}
            requiredClass={{
              fontSize: "70%",
            }}
            required={false}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const InformationTab = (props) => {
  const { leadSource, states, register, watch, offices, errors, trigger } =
    props;
  return (
    <div className="col-sm-12 mr-t30">
      <div className="app-g-form mr-b30">
        <div className="row">
          <div className="col-sm-12">
            <div className="card-title">
              <h4>Information</h4>
            </div>
          </div>
          {offices && offices?.length > 0 && (
            <>
              <div className="col-sm-12">
                <div className="row">
                  {/* <div className="col-sm-12 mr-b30">
                    <GoogleAutoComplete
                      notRequired={true}
                      groupFieldName={{
                        street: `desired_propertyaddress`,
                        city: `desired_city`,
                        state: `desired_state`,
                        zip: `zipcode`,
                        state_data: states?.map((item) => ({
                          label: item.StateName,
                          value: item.ID,
                        })),
                      }}
                      register={register(`addressSearch`)}
                      label={"Search  Address"}
                    />
                  </div> */}
                  <div className="col-sm-4 mr-b30">
                    <InputField2
                      label="Desired Property Address"
                      source="desired_propertyaddress"
                      placeholder="Desired Property Address"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>
                  <div className="col-sm-4 mr-b30">
                    <InputField2
                      label="Desired City"
                      source="desired_city"
                      placeholder="Desired City"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4 mr-b30">
                    <InputSelectField
                      label="Desired State"
                      source={`desired_state`}
                      options={states?.map((item) => ({
                        label: item.StateShortCode + " - " + item.StateName,
                        value: item.ID,
                      }))}
                      stopAutoFocus={true}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                    />
                  </div>
                  <div className="col-sm-4 mr-b30">
                    <InputCurrencyField
                      label="Desired Rent"
                      source={`desired_rent`}
                      required={true}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                      handleOnChange={() => {
                        trigger(`desired_rent`);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-sm-12">
            <div className="row">
              {offices && offices?.length > 0 && (
                <div className="col-sm-4 mr-b30">
                  <InputDateField
                    source={"desired_moveindate"}
                    label="Desired Move In Date"
                    labelClass={"lable-title"}
                    minDate={new Date()}
                  />
                </div>
              )}
              <div className="col-sm-4 mr-b30">
                <InputSelectField
                  label="How did you hear about us?"
                  stopAutoFocus={true}
                  source={`hearaboutus`}
                  options={leadSource?.map((item) => ({
                    label: item.LeadSource,
                    value: item.ID,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              {watch().hearaboutus?.label == "Other" ? (
                <div className="col-sm-4">
                  <InputField2
                    label="Lead Source Name"
                    source="other_lead_source"
                    placeholder="Lead Source Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <div className="mr-b30">
              <div className="cus-radio mr-b3">
                <label htmlFor="ta" className="d-flex align-align-items-center">
                  <input
                    type="radio"
                    id="ta"
                    {...register("is_behalfofapplicant")}
                    value={0}
                  />
                  <span className="mr-r10"></span>I am the applicant.
                </label>
              </div>
              <div className="cus-radio mr-b3">
                <label htmlFor="ra" className="d-flex align-align-items-center">
                  <input
                    type="radio"
                    id="ra"
                    {...register("is_behalfofapplicant")}
                    value={1}
                  />
                  <span className="mr-r10"></span>I am the subscriber completing
                  the application on behalf of the applicant
                </label>
              </div>

              {watch("is_behalfofapplicant") == 1 ? (
                <div className="cus-radio mr-l30 mt-2">
                  <label
                    htmlFor="ra"
                    className="d-flex align-align-items-center"
                  >
                    <input
                      type="checkbox"
                      id="ra"
                      {...register("is_subscriber")}
                      value={1}
                    />
                    <span className="mr-r10"></span>I am completing and signing
                    this application on behalf of {watch(`applicant.${0}.first_name`) != "" && watch(`applicant.${0}.last_name`) != "" ? watch(`applicant.${0}.first_name`) + ' ' + watch(`applicant.${0}.last_name`)  : "Applicant"} and hereby
                    certify that I have received separate signed consent from
                    the applicant which contains all of the consent language
                    contained herein.
                  </label>
                  <div
                    className="invalid-feedback"
                    style={{ display: "block", fontSize: "70%" }}
                  >
                    {errors ? errors?.is_subscriber?.message : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {errors ? errors?.is_behalfofapplicant?.message : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function NewApplicationModel({ isSuperUser = false }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSelect, setOpenSelect] = React.useState(false);
  const handleOpenSelect = () => setOpenSelect(true);
  const handleCloseSelect = () => setOpenSelect(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();

  // state for ceck email is already exist
  const [checkIsEmail, setCheckIsEmail] = useState(false);
  const [selectedAppType, setSelectedAppType] = useState("");

  // get id for application id
  const { id: urlId } = useParams();
  // const {auth}=useGetIdentity();
  // get user role from  global store
  const { data: identity }: any = useGetIdentity();
  const { permissions } = usePermissions();
  // const {subscriberid:id}= identity;
  const id = identity?.subscriberid || urlId;
  const userRole =
    useSelector((state: any) => state?.users?.role) || identity?.userrole;
  // get aspNetUserId from global store
  const aspNetUserId = useSelector((state: any) => state?.users?.aspNetUserId);
  // const isSuperUser = true;
  const [subscriberId, setSubscriberId] = useState(null);
  const [subscriberName, setSubscriberName] = useState(null);
  // state for disabled a button
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  // get application detail data
  const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
    "getnewapplicationdetail",
    {
      id: isSuperUser ? subscriberId : id,
    },
    { enabled: isSuperUser ? (subscriberId ? true : false) : id ? true : false }
  );
  // queryClient
  const queryClient = new QueryClient();



  // form validation
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      invite_type: Yup.string().nullable().required("Required!"),
      application_type: Yup.string().nullable().required("Required!"),
      office:
        applicationDetail?.SubscriberType === "Real Estate" &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          .required("Required!"),
      agent:
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()

          .required("Required!"),
      // floor:
      //   applicationDetail?.Properties &&
      //   applicationDetail?.Properties?.length > 0 &&
      //   Yup.object()
      //     .shape({
      //       value: Yup.string().required("Required!").nullable(),
      //       label: Yup.string().nullable(),
      //     })
      //     .nullable()
      //     .required("Required!"),
      // unit:
      //   applicationDetail?.Properties &&
      //   applicationDetail?.Properties?.length > 0 &&
      //   Yup.object()
      //     .shape({
      //       value: Yup.string().required("Required!").nullable(),
      //       label: Yup.string().nullable(),
      //     })
      //     .nullable()
      //     .required("Required!"),
      property: applicationDetail?.SubscriberType === "Property Management" &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          .required("Required!"),
      applicant: Yup.array().of(
        Yup.object().shape({
          // first_name: Yup.string().trim("Required!").required("Required!"),
          // last_name: Yup.string().trim("Required!").required("Required!"),
          first_name: Yup.string()
            .max(75, "maximum 75 characters is required")
            .required("required")
            .min(2, "minimum 2 characters is required")
            .typeError('required'),
          last_name: Yup.string()
            .max(75, "maximum 75 characters is required")
            .required("required")
            .min(2, "minimum 2 characters is required")
            .typeError('required'),
          email:
            value?.is_behalfofapplicant == 1
              ? Yup.string()
                .email("Invalid email format")
                .max(75, "maximum 75 characters is required")
                .required("Email is required!")
                .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain")
                .when(["username", "role"], {
                  is: (username, role) =>
                    (username == "" && role == "applicant") ||
                    (username == null && role == "applicant"),
                  then: Yup.string().test(
                    "isUniqueEmail",
                    "This email is already registered.",
                    async (value) => {
                      console.log(value, "value");
                      if (value !== "") {
                        const result = await queryClient.fetchQuery(
                          "UniqueEmail",
                          () =>
                            dataProvidersAts
                              .checkEmailExist({
                                email: value,
                                username: null,
                              })
                              .then((res) => res.data.data)
                          // { staleTime: 1000 }
                        );
                        setCheckIsEmail(result);
                        return result ? false : true;
                      } else {
                        return true;
                      }
                    }
                  ),
                })
              // .test('duplicateEmail', 'Applicant and Co-Applicant and Co-signer Email can not be same', (value) => {
              //   const email = value ?? '';
              //   console.log("value",email)
              //   if(email !== ""){
              //     console.log("value 2",email)
              //     console.log("fields",fields)
              //     const filteredEmails = fields.filter(record => record.email === value);
              //    if(filteredEmails.length > 0){
              //     return false;
              //    }else {
              //     return true;
              //    }

              //   } 
              //   return true;
              //  })
              : Yup.string()
                .email("Invalid email format")
                .required("Email is required!")
                .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
          // .test('duplicateEmail', 'Applicant and Co-Applicant and Co-signer Email can not be same', (value) => {
          //   const email = value ?? '';
          //   console.log("value",email)

          //   if(email !== ""){
          //     console.log("value 2",email)
          //     console.log("fields",fields)
          //     const applicants = watch("applicant");
          //     console.log("applicants",applicants)
          //     const filteredEmails = fields.filter(record => record.email === value);
          //    if(filteredEmails.length > 0){
          //     return false;
          //    }else {
          //     return true;
          //    }

          //   } 
          //   return true;
          //  }),
          confirm_email: Yup.string()
            .oneOf([Yup.ref("email"), ""], "Email must match!")
            .max(75, "maximum 75 characters is required")
            .required("Confirm Email is required!")
            .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
          username:
            value?.is_behalfofapplicant == 1
              ? Yup.string()
                .when("role", {
                  is: "applicant",
                  then: Yup.string()
                    .test("Emailexist", "Required!", async (value) => {
                      return (value == "" || value == null) && checkIsEmail
                        ? false
                        : true;
                    })
                    .trim("Required!")
                    .email("Invalid email format")
                    .max(75, "maximum 75 characters is required")
                    .nullable()
                    .when("email", {
                      is: (email: any) => email !== "",
                      then: Yup.string()
                        .test(
                          "isUniqueUserName",
                          "User name already used please enter different user name.",
                          async (value) => {
                            if (value !== null) {
                              const result = await queryClient.fetchQuery(
                                "UniqueUserName",
                                () =>
                                  dataProvidersAts
                                    .checkEmailExist({
                                      email: null,
                                      username: value,
                                    })
                                    .then((res) => res.data.data)
                              );
                              return result ? false : true;
                            } else {
                              return true;
                            }
                          }
                        )
                        .nullable(),
                    }),
                })
                .nullable()
              : Yup.string().nullable(),
          // mobile_number: Yup.string()
          //   .required("Required!")
          //   .typeError("Required!")
          //   .min(10, "Min 10 digits are required"),
          mobile_number: Yup.string()
            // .when("sentinvite_sms", {
            //   is: "1",
            //   then: Yup.string()
            //     .required("Required!")
            //     .typeError("Required!")
            //     .test("rent", "Min 10 digits are required", (value) => {
            //       let temp: any = value?.toString().replace(/\D/g, "");
            //       return temp?.length > 10 ? true : false;
            //     }),
            //   // .min(10, "Min 10 digits are required"),
            // })
            .required("Required!")
            .typeError("Required!")
            .test("rent", "Min 10 digits are required", (value) => {
              let temp: any = value?.toString().replace(/\D/g, "");
              return temp?.length > 10 ? true : false;
            })
            .nullable(),
        })
      ),
      desired_propertyaddress:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.string()
          .trim("Required!")
          .required("Required!")
          .nullable()

          .matches(/^$|^\S+.*/g, "Enter a valid value")
          .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value"),
      desired_city:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.string()
          .trim("Required!")
          .required("Required!")
          .nullable()
          .matches(/^$|^\S+.*/g, "Enter a valid value")
          .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value"),
      desired_rent:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.string()
          .required("Required!")
          .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
          .min(1, "Greater than 0 value required")
          .nullable()
          .test("rent", "Required!", async (value) => {
            return value == null || parseInt(value) > 0 ? true : false;
          }),
      desired_state:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          .required("Required!"),
      desired_moveindate:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        applicationDetail?.Offices &&
        applicationDetail?.Offices?.length > 0 &&
        Yup.string().required("Required!").nullable().typeError("Required!"),
      hearaboutus:
        value.invite_type == "Self" &&
        value.application_type == "Application Invite" &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          .required("Required!"),
      other_lead_source: Yup.string()
        .when("hearaboutus", {
          is: (value) => (value?.label == "Other" ? true : false),
          then: Yup.string().nullable().trim("Required!").required("Required!"),
        })
        .nullable(),

      is_behalfofapplicant: Yup.string()
        .when(["invite_type", "application_type"], {
          is: (invite_type, application_type) =>
            invite_type == "Self" && application_type == "Application Invite",
          then: Yup.string().required("Required!").nullable(),
        })
        .nullable(),
      is_subscriber: Yup.boolean().when(['is_behalfofapplicant', 'application_type', 'invite_type'], {
        is: (is_behalfofapplicant, application_type, invite_type) =>
          invite_type == "Self" && application_type == "Application Invite" && is_behalfofapplicant == "1",
        then: Yup.boolean().oneOf([true], 'You must accept the terms and conditions.').typeError("You must accept the terms and conditions."),
        otherwise: Yup.boolean().nullable(),
      }),

    })
  );


  // Initial form values
  const defaultFormValues = {
    invite_type: null,
    application_type: null,
    subscriberid: id,
    is_testapplication: 0,
    office: null,
    agent: null,
    floor: null,
    unit: null,
    property: null,
    applicant: [
      {
        first_name: "",
        last_name: "",
        email: "",
        confirm_email: "",
        username: null,
        mobile_number: "",
        sentinvite_email: "1",
        sentinvite_sms: "0",
        role: "applicant",
      },
    ],
    is_behalfofapplicant: null,
    // createdby: "f60913c7-bddd-4005-b3c5-b58e7f1d5a6d",
    createdby: aspNetUserId,
    is_subscriber: false,
    desired_propertyaddress: null,
    desired_city: null,
    desired_state: null,
    desired_moveindate: null,
    desired_rent: null,
    desired_unitno: null,
    hearaboutus: null,
    other_lead_source: null,
  };

  //   Initial call for useForm or initial state
  const form = useForm({
    mode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  // destructuring a form values
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = form;

  // state for dynamic fields
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "applicant",
    });
  const [redirectUrl, setRedirectUrl] = useState('');
  // form onsubmit Handler
  const onSubmit = (value) => {
    const { is_subscriber, addressSearch, ...rest } = value;

    const sendData = {
      ...rest,
      subscriberid: isSuperUser ? subscriberId : id,
      applicant: rest.applicant.map((item) => {
        return {
          ...item,
          sentinvite_email:
            rest.invite_type == "Email"
              // ? parseInt(item.sentinvite_email)
              // : item?.role === "applicant" && rest?.application_type !== "Doc Upload" && rest.invite_type == 'Self'
              ? 1
              : 0,
          sentinvite_sms: parseInt(item.sentinvite_sms),
          username:
            item?.role == "applicant" && rest?.is_behalfofapplicant == 1
              ? item?.username
              : null,
        };
      }),
      is_behalfofapplicant: parseInt(rest.is_behalfofapplicant),
      desired_moveindate: value?.desired_moveindate
        ? moment(value?.desired_moveindate).format("MM/DD/YYYY")
        : null,
    };
    create(
      "addnewapplication",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`Request processed successfully.`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          if (value?.invite_type !== "Email") {
            const newWindow = window.open(data?.data);
            if (
              !newWindow || newWindow.closed ||
              typeof newWindow.closed === "undefined"
            ) {
              // Popup blocker is enabled
              setRedirectUrl(data?.data);
              return alert("Please disable your popup blocker.");
            }
          }
          refresh();
          handleClose();
          reset();
          setCheckIsEmail(false);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } }),
      }
    );
  };

  // call get data for subscriberlist (getsubscribers)
  const {
    data: subscriberlist,
    isLoading: subscriberlistIsLoading,
    error: subscriberlistError,
  } = useGetList("getsubscribers", {}, { enabled: openSelect });

  // form validation
  const subscriberValidationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      subscriber: Yup.object()
        .shape({
          value: Yup.string().required("Required!").nullable().max(75, "maximum 75 characters is required"),
          label: Yup.string().nullable(),
        })
        .nullable()
        .required("Subscriber is required!"),
    })
  );

  // Initial call for useForm or initial state
  const subscriberForm = useForm({
    defaultValues: {
      subscriber: null,
    },
    mode: "onChange",
    resolver: yupResolver(subscriberValidationSchema),
  });

  // on Submit Subscriber Handler
  const onSubmitSubscriber = (value) => {
    setSubscriberId(value?.subscriber?.value);
    setSubscriberName(value?.subscriber?.label);
    handleCloseSelect();
    handleOpen();
    subscriberForm?.reset();
  };

  const onClickButtonHandler = () => {
    setButtonIsDisabled(true);
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (buttonIsDisabled) {
      const timer = setTimeout(() => {
        setButtonIsDisabled(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [buttonIsDisabled]);
  const copyURL = (currentURL) => {
    // const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        alert("URL copied to clipboard!");
        refresh();
        handleClose();
        reset();
        setCheckIsEmail(false);
        setRedirectUrl('');
      })
      .catch((error) => {
        alert("Failed to copy URL to clipboard: " + error);
      });
  };

  const applicationTypes = applicationDetail?.ApplicationType || {};
  const applicationTypesKeys = Object.entries(applicationTypes);

  let defaultRadioButton;

  defaultRadioButton =
    applicationTypesKeys.filter((val) => val[1])[0] !== undefined &&
    applicationTypesKeys.filter((val) => val[1]).length === 1 &&
    applicationTypesKeys.filter((val) => val[1])[0][0];


  return (
    <>
      {(isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Send Invite"), identity?.userrole) ||
        isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Start App"), identity?.userrole)) && (
          <button
            type="button"
            onClick={() => {
              subscriberForm?.reset();
              reset();
              setCheckIsEmail(false);
              isSuperUser ? handleOpenSelect() : handleOpen();
            }}
            className="blue-btn px-4"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Application
          </button>
        )}

      <Dialog
        className="cus-modal-container"
        fullWidth={true}
        maxWidth={"xl"}
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "964px" } }}
        open={open}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="dialog_title_new_app">
          New Application {subscriberName ? `- ${subscriberName}` : ""}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {/* <span style={{fontSize:"12px"}}>Dismiss</span> */}
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content_new_app">
          {/* Content here */}
          <div className="cus-modal-body">
            {!redirectUrl ? (
              <FormProvider {...form}>
                <form onSubmit={handleSubmit(() => { })}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-auto">
                          <div className="cus-input app-option-btn mr-b30">
                            <label
                              htmlFor=""
                              className={`lable-title ${errors &&
                                errors?.invite_type?.message &&
                                "text-danger"
                                }`}
                            >
                              Invite Type <small className="color-red">*</small>
                            </label>

                            <div className="it-option" id="a-oprtions">
                              {isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Send Invite"), identity?.userrole) && <label
                                htmlFor="invite_type"
                                className={`${!watch("invite_type") && "backgroundCRD"
                                  } ${errors &&
                                  errors?.invite_type?.message &&
                                  "backgroundCRD"
                                  }`}
                              >
                                <input
                                  type="radio"
                                  id="invite_type"
                                  value="Email"
                                  {...register("invite_type")}
                                />
                                <span
                                  onClick={() => {
                                    //reset();
                                    setCheckIsEmail(false);
                                  }}
                                >
                                  Send Invite
                                </span>
                              </label>}
                              {isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Start App"), identity?.userrole) && <label
                                htmlFor="invite_type2"
                                className={`${!watch("invite_type") && "backgroundCRD"
                                  } ${errors &&
                                  errors?.invite_type?.message &&
                                  "backgroundCRD"
                                  }`}
                              >
                                <input
                                  type="radio"
                                  id="invite_type2"
                                  {...register("invite_type")}
                                  value="Self"
                                />
                                <span
                                  onClick={() => {
                                    //reset();
                                    setCheckIsEmail(false);
                                  }}
                                >
                                  Start App
                                </span>
                              </label>}
                            </div>
                            <div
                              className="invalid-feedback"
                              style={{ display: "block", fontSize: "70%" }}
                            >
                              {errors ? errors?.invite_type?.message : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="cus-input app-option-btn mr-b30">
                            <label
                              htmlFor=""
                              className={`lable-title ${errors &&
                                errors?.application_type?.message &&
                                "text-danger"
                                }`}
                            >
                              Application Type{" "}
                              <small className="color-red">*</small>
                            </label>
                            <div className="it-option" id="a-oprtions2">
                              {applicationDetail?.ApplicationType?.ATS && isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Application"), identity?.userrole) && (
                                <label
                                  htmlFor="application_type"
                                  className={`${!watch("application_type") &&
                                    "backgroundCRD"
                                    } ${errors &&
                                    errors?.application_type?.message &&
                                    "backgroundCRD"
                                    }`}
                                >
                                  <input
                                    type="radio"
                                    id="application_type"
                                    value="Application Invite"
                                    {...register("application_type")}
                                    defaultChecked={
                                      defaultRadioButton === "ATS"
                                    }
                                  //onChange={() => setSelectedAppType("Application Invite")}
                                  />
                                  <span
                                    onClick={() => {
                                      setCheckIsEmail(false);
                                    }}
                                  >
                                    Application
                                  </span>
                                </label>
                              )}
                              {applicationDetail?.ApplicationType
                                ?.DocUpload && isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Docupload"), identity?.userrole) && (
                                  <label
                                    htmlFor="application_type2"
                                    className={`${!watch("application_type") &&
                                      "backgroundCRD"
                                      } ${errors &&
                                      errors?.application_type?.message &&
                                      "backgroundCRD"
                                      }`}
                                  >
                                    <input
                                      type="radio"
                                      id="application_type2"
                                      value="Doc Upload"
                                      {...register("application_type")}
                                      defaultChecked={
                                        defaultRadioButton === "DocUpload"
                                      }
                                    />
                                    <span
                                      onClick={() => {
                                        setCheckIsEmail(false);
                                      }}
                                    >
                                      Doc Upload
                                    </span>
                                  </label>
                                )}
                              {applicationDetail?.ApplicationType
                                ?.GuestCard && isSuperAdmin(checkModuleAccess(permissions, "Dashboard", "Guestcard"), identity?.userrole) && (
                                  <label
                                    htmlFor="application_type3"
                                    className={`${!watch("application_type") &&
                                      "backgroundCRD"
                                      } ${errors &&
                                      errors?.application_type?.message &&
                                      "backgroundCRD"
                                      }`}
                                  >
                                    <input
                                      type="radio"
                                      id="application_type3"
                                      value="Guest Card"
                                      {...register("application_type")}
                                      defaultChecked={
                                        defaultRadioButton === "GuestCard"
                                      }
                                    />
                                    <span
                                      onClick={() => {
                                        setCheckIsEmail(false);
                                      }}
                                    >
                                      Guest Card
                                    </span>
                                  </label>
                                )}
                            </div>
                            <div
                              className="invalid-feedback"
                              style={{ display: "block", fontSize: "70%" }}
                            >
                              {errors ? errors?.application_type?.message : ""}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-auto">
                        <div className="app-list-block mr-b30">
                          <div className="cus-check mr-b3">
                            <label
                              htmlFor="ta"
                              className="d-flex align-align-items-center"
                            >
                              <input
                                type="checkbox"
                                id="ta"
                                // {...register("is_testapplication")}
                                value={0}
                                onChange={(e) => {
                                  setValue(
                                    "is_testapplication",
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                              <span className="mr-r10"></span>
                              Test Application
                            </label>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    {(applicationDetail?.Properties &&
                      applicationDetail?.Properties?.length > 0) ? (
                      <>
                        <div className="col-sm-4 mr-b30">
                          <InputSelectField
                            label="Property"
                            source={`property`}
                            options={applicationDetail?.Properties?.map(
                              (item) => ({
                                label: item.PropertyName,
                                value: item.ID,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            onChangeCallback={() => {
                              setValue("floor", null);
                              setValue("unit", null);
                            }}
                          />
                        </div>

                        <FloorPlan
                          FloorPlan={applicationDetail?.Properties}
                          // register={register}
                          watch={watch}
                          setValue={setValue}
                        />
                      </>
                    ) : (applicationDetail?.SubscriberType === "Property Management" ?
                      <div className="col-sm-4 mr-b30">
                        <InputSelectField
                          label="Property"
                          source={`property`}
                          options={[]}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                        />
                      </div> : ("")
                    )}
                    {applicationDetail?.Offices &&
                      applicationDetail?.Offices?.length > 0 ? (
                      <>
                        {" "}
                        <div className="col-sm-4 mr-b30">
                          <InputSelectField
                            label="Office"
                            source={`office`}
                            options={applicationDetail?.Offices?.map(
                              (item) => ({
                                label: item.OfficeName,
                                value: item.ID,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            onChangeCallback={() => setValue("agent", null)}
                          />
                        </div>
                        <AgentList
                          Office={applicationDetail?.Offices}
                          // register={register}
                          // fieldValue={watch().office}
                          // Office={userRole === "Docuverus Super Admin" ? applicationDetail?.Offices : applicationDetail?.UserURL}  // Pass Office if role is super admin
                          newuserurl={applicationDetail?.UserURL}  // Pass newuserurl if role is not super admin
                          register={register}
                          fieldValue={watch().office}
                        />
                      </>
                    ) : (applicationDetail?.SubscriberType === "Real Estate" ?
                      <div className="col-sm-4 mr-b30">
                        <InputSelectField
                          label="Office"
                          source={`office`}
                          options={[]}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                        />
                      </div> : ("")
                    )}
                    {/*  */}
                    <div className="col-sm-12">
                      {fields.map((item, index) => {
                        return (
                          <div className="col-sm-12" key={item.id}>
                            <div className="app-g-form mr-b30">
                              <div className="row">
                                {item.role !== "applicant" ? (
                                  <div className="col-sm-12">
                                    <div className="card-title">
                                      <h4>
                                        {item.role === "applicant"
                                          ? ""
                                          : item.role === "Co-applicant"
                                            ? "Co-applicant"
                                            : item.role === "Co-signer"
                                              ? "Co-signer"
                                              : ""}
                                      </h4>
                                      {fields?.length > 1 &&
                                        (item.role === "Co-applicant" ||
                                          item.role === "Co-signer") && (
                                          <button
                                            type="button"
                                            onClick={() => remove(index)}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.3333 3.00004H13.6667V4.33337H12.3333V13C12.3333 13.1769 12.2631 13.3464 12.1381 13.4714C12.013 13.5965 11.8435 13.6667 11.6667 13.6667H2.33333C2.15652 13.6667 1.98695 13.5965 1.86192 13.4714C1.7369 13.3464 1.66666 13.1769 1.66666 13V4.33337H0.333328V3.00004H3.66666V1.00004C3.66666 0.82323 3.7369 0.65366 3.86192 0.528636C3.98695 0.403612 4.15652 0.333374 4.33333 0.333374H9.66666C9.84347 0.333374 10.013 0.403612 10.1381 0.528636C10.2631 0.65366 10.3333 0.82323 10.3333 1.00004V3.00004ZM11 4.33337H2.99999V12.3334H11V4.33337ZM7.94266 8.33337L9.12133 9.51204L8.17866 10.4547L7 9.27604L5.82133 10.4547L4.87866 9.51204L6.05733 8.33337L4.87866 7.15471L5.82133 6.21204L7 7.39071L8.17866 6.21204L9.12133 7.15471L7.94266 8.33337ZM5 1.66671V3.00004H9V1.66671H5Z"
                                                fill="#2DC3E8"
                                              />
                                            </svg>

                                            {item.role === "Co-applicant"
                                              ? "Co-applicant"
                                              : item.role === "Co-signer"
                                                ? "Co-signer"
                                                : ""}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-sm-4 mr-b30">
                                  <InputField2
                                    label="First Name"
                                    source={`applicant.${index}.first_name`}
                                    placeholder="First Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                                <div className="col-sm-4 mr-b30">
                                  <InputField2
                                    label="Last Name"
                                    source={`applicant.${index}.last_name`}
                                    placeholder="Last Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                                {watch("invite_type") !== "Self" ? (
                                  <div className="col-sm-4">
                                    <div className="cus-input app-option-btn mr-b30">
                                      <label htmlFor="" className="lable-title">
                                        Send Invite via Email{" "}
                                        <small className="color-red">*</small>
                                      </label>
                                      <div
                                        className="it-option"
                                        id="sie-oprtions"
                                      >
                                        <label
                                          htmlFor={`applicant.${index}.sentinvite_email`}
                                        >
                                          <input
                                            type="radio"
                                            id={`applicant.${index}.sentinvite_email`}
                                            {...register(
                                              `applicant.${index}.sentinvite_email`
                                            )}
                                            value="1"
                                            disabled={true}
                                          />
                                          <span
                                            style={{ cursor: "not-allowed" }}
                                          >
                                            Yes
                                          </span>
                                        </label>
                                        <label
                                          htmlFor={`applicant.${index}.sentinvite_email1`}
                                        >
                                          <input
                                            type="radio"
                                            id={`applicant.${index}.sentinvite_email1`}
                                            {...register(
                                              `applicant.${index}.sentinvite_email`
                                            )}
                                            value="0"
                                            disabled={true}
                                          />
                                          <span
                                            style={{ cursor: "not-allowed" }}
                                          >
                                            No
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-sm-4 mr-b30">
                                  <InputField2
                                    label="Email"
                                    source={`applicant.${index}.email`}
                                    placeholder="Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    handleOnChange={() =>
                                      trigger(
                                        `applicant.${index}.confirm_email`
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-sm-4 mr-b30">
                                  <InputField2
                                    label="Confirm Email"
                                    source={`applicant.${index}.confirm_email`}
                                    placeholder="Confirm Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                                </div>
                                {watch(`applicant.${index}.role`) ==
                                  "applicant" &&
                                  watch(`is_behalfofapplicant`) == 1 &&
                                  checkIsEmail ? (
                                  <div className="col-sm-4 mr-b30">
                                    <InputField2
                                      handleOnChange={() =>
                                        trigger(`applicant.${index}.email`)
                                      }
                                      label="Username"
                                      source={`applicant.${index}.username`}
                                      placeholder="Username"
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* {watch("invite_type") == "Email" ||
                                (watch(`applicant.${index}.role`) ==
                                  "applicant" &&
                                  watch(`is_behalfofapplicant`) == 1 &&
                                  checkIsEmail) ? (
                                <div className="w-100"></div>
                              ) : (
                                ""
                              )} */}
                                {watch("invite_type") !== "Self" ? (
                                  <div className="col-sm-4">
                                    <div className="cus-input app-option-btn mr-b30">
                                      <label htmlFor="" className="lable-title">
                                        Send Invite via text / SMS{" "}
                                        <small className="color-red">*</small>
                                      </label>
                                      <div
                                        className="it-option"
                                        id="sies-oprtions"
                                      >
                                        <label
                                          htmlFor={`applicant.${index}.sentinvite_sms`}
                                        >
                                          <input
                                            type="radio"
                                            id={`applicant.${index}.sentinvite_sms`}
                                            value="1"
                                            {...register(
                                              `applicant.${index}.sentinvite_sms`
                                            )}
                                            onClick={() => {
                                              trigger(
                                                `applicant.${index}.mobile_number`
                                              );
                                            }}
                                          />
                                          <span>Yes</span>
                                        </label>
                                        <label
                                          htmlFor={`applicant.${index}.sentinvite_sms1`}
                                        >
                                          <input
                                            type="radio"
                                            id={`applicant.${index}.sentinvite_sms1`}
                                            value="0"
                                            {...register(
                                              `applicant.${index}.sentinvite_sms`
                                            )}
                                            onClick={() => {
                                              trigger(
                                                `applicant.${index}.mobile_number`
                                              );
                                            }}
                                          />
                                          <span>No</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-sm-4">
                                  <TextInputPhone
                                    source={`applicant.${index}.mobile_number`}
                                    label="Mobile No."
                                    isCountryCode={`applicant.${index}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                      fontSize: "70%",
                                    }}
                                  // required={
                                  //   watch(
                                  //     `applicant.${index}.sentinvite_sms`
                                  //   ) == "1"
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <h5 className="data-rates-note mt-sm-5 position-static p-0">
                                    Data rates may apply
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/*  */}
                    {/* watch("application_type") == "Application Invite" ?  */}
                    {(
                      // || watch("application_type") == "Doc Upload" ? (
                      <div className="col-sm-12">
                        <div className="g-btm-btn d-flex align-items-center">
                          <button
                            type="button"
                            className="blue-line-btn mr-r20"
                            onClick={() => {
                              append({
                                first_name: "",
                                last_name: "",
                                email: "",
                                confirm_email: "",
                                username: null,
                                mobile_number: "",
                                // country_code: "",
                                sentinvite_email: "1",
                                sentinvite_sms: "0",
                                role: "Co-applicant",
                              });
                            }}
                          >
                            <svg
                              className="mr-r10"
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.33331 4.83337V0.833374H5.66665V4.83337H9.66665V6.16671H5.66665V10.1667H4.33331V6.16671H0.333313V4.83337H4.33331Z"
                                fill="#2DC3E8"
                              />
                            </svg>
                            Co-applicant
                          </button>
                          <button
                            type="button"
                            className="blue-line-btn"
                            onClick={() => {
                              append({
                                first_name: "",
                                last_name: "",
                                email: "",
                                confirm_email: "",
                                username: null,
                                mobile_number: "",
                                // country_code: "",
                                sentinvite_email: "1",
                                sentinvite_sms: "0",
                                role: "Co-signer",
                              });
                            }}
                          >
                            <svg
                              className="mr-r10"
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.33334 4.83337V0.833374H5.66668V4.83337H9.66668V6.16671H5.66668V10.1667H4.33334V6.16671H0.333344V4.83337H4.33334Z"
                                fill="#2DC3E8"
                              />
                            </svg>
                            Co-signer
                          </button>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {watch("invite_type") == "Self" &&
                      watch("application_type") == "Application Invite" && (
                        <InformationTab
                          leadSource={applicationDetail?.LeadSource}
                          states={applicationDetail?.States}
                          register={register}
                          watch={watch}
                          offices={applicationDetail?.Offices}
                          errors={errors}
                          trigger={trigger}
                        />
                      )}

                    <div className="col-sm-12">
                      <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                        <button
                          type="button"
                          className="blue-line-btn mr-r20"
                          onClick={() => {
                            handleClose();
                            reset();
                            setCheckIsEmail(false);
                          }}
                        >
                          <svg
                            className="mr-r10"
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                              fill="#2DC3E8"
                            />
                          </svg>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="blue-btn"
                          disabled={buttonIsDisabled}
                          onClick={onClickButtonHandler}
                        >
                          <svg
                            className="mr-r10"
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                              fill="white"
                            />
                          </svg>

                          {watch("invite_type") == "Self"
                            ? "Start Application"
                            : "Send Invite"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            ) : (
              <>
                <p>Your popup blocker is active. Click to copy URL</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    copyURL(redirectUrl);
                  }}
                >
                  {redirectUrl}
                </a>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {/*  */}
      <Dialog
        fullWidth
        open={openSelect}
        onClose={handleCloseSelect}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paperFullWidth ": { height: "390px" } }}
      >
        <DialogTitle>Select Subscriber</DialogTitle>
        <DialogContent>
          {subscriberlistIsLoading ? (
            <>Loading...</>
          ) : (
            <FormProvider {...subscriberForm}>
              <form onSubmit={subscriberForm.handleSubmit(onSubmitSubscriber)}>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-12 mr-b20">
                    <InputSelectField
                      label="Subscriber"
                      source={`subscriber`}
                      options={subscriberlist?.map((item) => ({
                        label: item.value,
                        value: item.id,
                      }))}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 mr-b30">
                  <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                    <button
                      type="button"
                      className="blue-line-btn mr-r20"
                      onClick={() => {
                        handleCloseSelect();
                        subscriberForm?.reset();
                      }}
                    >
                      <svg
                        className="mr-r10"
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                      Cancel
                    </button>
                    <button type="submit" className="blue-btn">
                      <svg
                        className="mr-r10"
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                          fill="white"
                        />
                      </svg>
                      Proceed
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
      </Dialog>
      {/*  */}
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
}
