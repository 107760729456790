import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { BiTrash } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiInformationLine } from "react-icons/ri";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SyncIcon from "@mui/icons-material/Sync";
import overa from "../../../assets/images/overa.svg";
import redTick from "../../../assets/images/redTick.svg";
import greenTick from "../../../assets/images/greenTick.svg";
import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Alert,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import CustomRadioButton from "../../common/InputComponents/CustomRadioButton";
import MaskInputField from "../components/inputComponents/MaskInputField";
import NoDocReasonRadioButtons from "../../common/InputComponents/NoDocReasonRadioButtons";
import InputField2 from "../../common/InputComponents/InputField2";
import DragDropDocumentButton from "../components/DragDropDocumentButton";
import AlertDialog from "../../common/AlertDialog";
import FormInstruction from "../../common/FormInstruction";
import FilePreview from "../../common/FilePreview";
import CustomPreviewUpload from "../../common/CustomPreviewUpload";
import InputMaskField from "../../common/InputComponents/InputMaskFieid";
import { useMutation } from "react-query";
import { useDataProvider, useGetIdentity, useNotify, useRefresh } from "react-admin";
import ShowDocumentStatus from "../components/ShowDocumentStatus";
import { RootState } from "../../../Store/store";
import { JSONTree } from "react-json-tree";
import { useUserRole } from "../../../ContextApi/UserRoleContext";
import AddDocuverusComment from "../Modals/AddDocuverusComment";
import ShowComments from "../components/ShowComments";

const SsnSection = (props) => {
  const { record, role } = props
  const [ssnInformation, setSnnInformation] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectOtherRadioButton, setSelectOtherRadioButton] =
    React.useState(false);
  const { identity, isLoading: identityLoading } = useGetIdentity();
  // const { role } = useUserRole();
  const userRoleRedux = useSelector((state: RootState) => state.users.role);
  const userRole = role || userRoleRedux;
  const [deleteSSN, setDeleteSSN] = React.useState(false);

  const { register, watch, getValues, trigger, formState, setValue } =
    useFormContext();

  const dataProvider = useDataProvider();
  // const queryClient = useQueryClient();
  const notify = useNotify();
  const refresh = useRefresh();
  const { mutate, isLoading, isSuccess } = useMutation(
    "delete_document_ssn_document",
    (id) => dataProvider.deleteDocument(id),
    {
      onSuccess: (data) => {
        // setConfirmDelete(false);
        // queryClient.invalidateQueries([`${invalidateQueries}`])
        refresh();
        notify("Document deleted successfully...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        //
        //
        setDeleteSSN(false);
        setValue("ssn_data.file", "");
        setValue("ssn_data.file_image", "");
        setValue("ssn_data.availableToUpload_ssn", "No");
        setFilePreview({
          ssn_id: "",
          ssn_filePath: "",
        });
        props?.handleAPIDebounced();
      },
      onError: (data) => {
        // setConfirmDelete(false);
        alert("Fail deleting document");
        setDeleteSSN(false);
      },
      onMutate: () => {
        notify("Document is Deleting...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const deleteDocumentHandler = (id) => {
    mutate(id);
  };

  useEffect(() => {
    //
    const subscribe: any = watch((value, { name, type }) => {
      //
      if (type === "change" && name === `ssn_data.isUS_citizen`) {
        trigger("ssn_data.SSNNumber");
      }
    });
    return () => subscribe.unsubscribe;
  }, [setValue, trigger, watch]);

  const [filePreview, setFilePreview] = useState({
    ssn_id: "",
    ssn_filePath: "",
  });
  const trn_id = props.Trnid; //useSelector((state: any) => state?.users?.userTrnId);

  const getSrcfromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return greenTick;
        case 2:
          return redTick;
        case 3:
          return overa;
      }
    } else {
      return overa;
    }
  };

  const getBlockStyleFromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "p-1 block text-center rounded bg-success text-white";
        case 2:
          return "p-1 block text-center rounded bg-danger text-white";
        case 3:
          return "p-1 block text-center rounded bg-warning text-dark";
      }
    } else {
      return "p-1 block text-center rounded bg-warning text-dark";
    }
  };

  const gettextStylefromStatus = (socrValue) => {
    return "";
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-ltr";
        case 2:
          return "red-ltr";
        case 3:
          return "brown-ltr";
      }
    } else {
      return "brown-ltr";
    }
  };
  const sectionComment = watch("ssn_data.section_comment");
  const section = sectionComment?.admin
  const parsedData = (() => {
    // Check if sectionComment is valid and can be parsed
    if (!sectionComment || sectionComment === "undefined") {
      return { error: "No comment available" };
    }
    try {
      return JSON.parse(sectionComment); // Attempt to parse the JSON string
    } catch (error) {
      return { error: "Invalid JSON format" };
    }
  })();
  return (
    <AccordionDetails>
      <>
        <div className="ssn-t-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group cus-input-group mr-b30 mr-t30">
                <CustomRadioButton
                  label="Are you a US citizen?"
                  register={register("ssn_data.isUS_citizen")}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <div className="emp-inputs">
                <div className="form-group cus-input-group mr-b30">
                  <InputMaskField
                    notRequired={true}
                    maxLength={9}
                    inputType="SSN"
                    label="SSN Number"
                    // register={register("ssn_data.SSNNumber")}
                    source={"ssn_data.SSNNumber"}
                    mask={"999-99-9999"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="i-a-block mb-2">


                <CustomRadioButton
                  onClick={() => {
                    if (!!watch("ssn_data?.file")) {
                      setDeleteSSN(true);
                    }
                  }}
                  notRequired={!props?.required}
                  disable={!!watch("ssn_data?.file")}
                  label="Do you have proof of SSN available?"
                  register={register("ssn_data.availableToUpload_ssn")}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>
            </div>

            {watch("ssn_data.availableToUpload_ssn") == "Yes" && (
              <>
                {watch("ssn_data")?.file && userRole != "applicant" && (
                  <div className="appl-status-wrap align-items-start">
                    <div className="d-flex flex-wrap">
                      {userRole === "Docuverus Super Admin" ? (
                        <div className="pr-2">
                          <ShowDocumentStatus
                            className={"col-lg col-sm-6 col-6 sub-aapl"}
                            trn_id={trn_id}
                            documentId={watch("ssn_data")?.documenttype_id}
                            SourceId={1}
                            value={
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            }
                            src={getSrcfromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            blockStyle={getBlockStyleFromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            textStyle={gettextStylefromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            // fetchRecord={() => null}
                            label={"Overall"}
                            invalidateQueries={"ssnGetidentification"}
                          />
                        </div>
                      ) : (
                        <div className="pr-2">
                          <ShowDocumentStatus
                            customWidth={150}
                            className={"col-lg col-sm-6 col-6 sub-aapl"}
                            trn_id={trn_id}
                            documentId={watch("ssn_data")?.documenttype_id}
                            SourceId={1}
                            value={
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            }
                            src={getSrcfromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            blockStyle={getBlockStyleFromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            textStyle={gettextStylefromStatus(
                              watch("ssn_data")?.manualsocr
                                ? watch("ssn_data")?.manualsocr
                                : watch("ssn_data")?.calculatedscocr
                            )}
                            disable={true}
                            label={"Overall"}
                            invalidateQueries={"ssnGetidentification"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}


                <div className={`card-body`}>
                  {(watch("ssn_data")?.file != null && userRole === "Docuverus Super Admin" &&
                    (
                      <ShowComments
                        comment_heading="Docuverus Internal Comment"
                        section_comment={watch("ssn_data")?.InternalComment}
                        role={userRole}
                      />
                    ))}
                  {watch("ssn_data")?.file != null && (
                    <ShowComments
                      section_comment={watch("ssn_data")?.section_comment}
                      role={userRole}
                    />
                  )}

                  {watch("ssn_data")?.file != null && watch("ssn_data")?.section_comment?.["applicant"] && watch("ssn_data")?.section_comment?.["applicant"] !== "" && (
                    <ShowComments
                      section_comment={watch("ssn_data").section_comment}
                      role={userRole}
                      comment_heading="Applicant Comment"
                    />
                  )}
                </div>



                <div className="col-sm-12">
                  <div className={`row mb-2  sb-blue m-1`}>
                    <div
                      className={
                        filePreview.ssn_id !== ""
                          ? "col-md-8 col-sm-12 "
                          : "col-md-12 col-sm-12 "
                      }
                    >
                      <div className="sb-blue mx-3 m-1">
                        <div className="blue-flex">
                          <div className="row align-items-center px-2 print-hide">





                            <div
                              className="col-sm-12 col-md-10"
                              style={{
                                fontSize: "11px",
                                color: "#606060",
                                paddingLeft: "16px",
                              }}
                            >
                              <div className="d-flex align-items-center ">
                                <strong>
                                  {`${1}. ${"Proof of SSN "}`}:*&nbsp;
                                </strong>
                                {/* <b>{`${"Proof of SSN"}`}:*&nbsp;</b> */}
                                <AlertDialog
                                  message={`Only upload clear, complete, straight documents.`}
                                />
                              </div>

                              <div className="d-flex align-items-center m-1">
                                <strong>(SSN card or un-redacted W2)</strong>
                              </div>
                              <FormInstruction
                                message={
                                  "Click the (i) button above for details"
                                }
                              />
                            </div>
                          </div>
                        </div>


                        <div className="row"> </div>
                        <div className="col-md-12">
                          <div className="d-flex flex-wrap mt-2">
                            {watch("ssn_data.file") == null ? (
                              <div className="">
                                <DragDropDocumentButton
                                  SourceId={1}
                                  documentId={4}
                                  fetchRecord={() => { }}
                                  acceptedFiles={`image/*,application/pdf`}
                                  invalidateQueries={"otherDoc"}
                                  tag={`fileOprations_other_income`}
                                  trn_id={trn_id}
                                  fileError={false}
                                />
                              </div>
                            ) : (
                              <div className="d-flex">
                                <div className="px-1">
                                  <FilePreview
                                    doc_id={getValues(
                                      `ssn_data.application_document_id`
                                    )}
                                    img={getValues(`ssn_data.file_image`)}
                                    tag={"delete_document_ssn_document"}
                                    invalidateQueries={"ssnDocData"}
                                    file={getValues(`ssn_data.file`)}
                                    handleClick={() =>
                                      setFilePreview({
                                        ...filePreview,
                                        ssn_id: getValues(
                                          `ssn_data.application_document_id`
                                        ),
                                        ssn_filePath:
                                          getValues(`ssn_data.file_image`),
                                      })
                                    }
                                    handleOnChange={() => {
                                      const isCurrentFile =
                                        filePreview.ssn_filePath ==
                                          getValues(`ssn_data.file_image`)
                                          ? true
                                          : false;

                                      if (isCurrentFile) {
                                        setFilePreview({
                                          ssn_id: "",
                                          ssn_filePath: "",

                                          // index: null,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                  
                          <div className="d-flex justify-content-end pt-4 print-hide mb-2">
                            {watch("ssn_data.availableToUpload_ssn") === "Yes" && watch("ssn_data.file") != null && userRole != "applicant"
                              &&
                              <AddDocuverusComment
                                label={userRole === 'applicant' ? `Add Comment` : 'Add/Edit Docuverus Comment'}
                                title={userRole === 'applicant' ? `Add Comment` : 'Add/Edit Docuverus Comment'}
                                defaultValue={
                                  userRole == "applicant"
                                    ? watch("ssn_data")?.section_comment?.applicant
                                    : watch("ssn_data")?.section_comment?.admin
                                }
                                defaultValueInternal={
                                  watch("ssn_data")?.InternalComment?.admin
                                }
                                trn_id={trn_id}
                                is_applicant={userRole === 'applicant' ? true : false}
                                document_id={4}
                                tag={'addComment_ssn'}
                                invalidateQueries={'ssnGetidentification'}
                                emp_id={null}
                                role={userRole == 'applicant' ? 'applicant' : userRole}
                              />

                            }

                          </div>
                        </div>
                      </div>

                    </div>
                    {filePreview.ssn_id !== "" && (
                      <div className="col-md-4 col-sm-12 mt-2 ">
                        <CustomPreviewUpload
                          filePath={filePreview.ssn_filePath}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}











            {watch(`ssn_data.availableToUpload_ssn`) == "No" && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="login-radio-types">
                      <NoDocReasonRadioButtons
                        handleOnchange={() => props.handleAPIDebounced()}
                        options={[
                          {
                            name: "I don't currently have access to document(s), but can provide later",
                            value:
                              "I don't currently have access to document(s), but can provide later",
                          },
                          {
                            name: "I do not have SSN",
                            value: "I do not have SSN",
                          },
                          {
                            value: "other",
                            name: "Other",
                          },
                        ]}
                        source={`ssn_data.no_doc_reason`}
                        section_id={7}
                      />
                      {watch("ssn_data.no_doc_reason") == "other" && (
                        <div>
                          <InputField2
                            label="Please Explain"
                            source={`ssn_data.other_reason`}
                            placeholder="Please Explain"
                            handleOnChange={() => props.handleAPIDebounced()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Stack
            sx={{ width: "100%" }}
            style={{ textTransform: "capitalize", marginTop: "10px" }}
            spacing={2}
          >
            <ErrorMessage
              errors={formState.errors}
              name={`ssn_data.file`}
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
          </Stack>
        </div>
        <Dialog
          open={ssnInformation}
          onClose={() => {
            setSnnInformation(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Only upload clear, complete, straight documents.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                setSnnInformation(false);
              }}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure want to delete document ?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning! Once deleted cannot revert back .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteSSN}
          onClose={() => {
            setDeleteSSN(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure want to delete
           ?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning! Once deleted cannot revert back .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-info text-light"
              onClick={() => {
                setDeleteSSN(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-info text-light"
              onClick={() => {
                setTimeout(() => {
                  deleteDocumentHandler(
                    watch("ssn_data.application_document_id")
                  );
                }, 200);
              }}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>
      </>
    </AccordionDetails>
  );
};

export default SsnSection;
